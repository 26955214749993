import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import './app.css';
import Header from './components/header/header.js';
import Footer from './components/footer/footer.js';
import Home from './components/home/home.js';
import Techstack from './components/techstack/techstack.js';
import Projects from './components/projects/projects.js';


export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Default states
      error: {},
      notification: {},
      loading: true,
      cookieConsent: false,
      displayCookieBanner: false
    };

    this.baseUrl = "http://localhost:6005";

    this.setError = this.setError.bind(this);
    this.unsetError = this.unsetError.bind(this);
    this.setNotification = this.setNotification.bind(this);
    this.unsetNotification = this.unsetNotification.bind(this);
  }


  componentDidMount() {
    try {
      // Set state to not loading
      this.setState({
        loading: false
      })
    } catch (error) {
      // Set error
      var name = "Website error";
      var message = "Website error";
      this.setError(name, message);
      // Track event
      //this.trackEvent("Test", "Client error: App_componentDidMount");
    }
  }


  setError(name, message) {
    // Create error object
    var error = {
      name: name,
      message: message
    }

    // Set error to state
    this.setState({
      error: error
    })
  }


  unsetError() {
    // Create empty error object
    var error = {}

    // Set error to state
    this.setState({
      error: error
    })
  }


  async setNotification(name, message) {
    // Create notification object
    var notification = {
      name: name,
      message: message
    }

    // Set error to state
    this.setState({
      notification: notification
    })

    // Close notification after 3 seconds
    const componentInstance = this;
    setTimeout(function () {
      componentInstance.unsetNotification();
    }, 8000);
  }


  unsetNotification() {
    var notification = {};

    // Unset notification in state
    this.setState({
      notification: notification
    });
  }


  render() {
    return (
      <div className="fullheight-wrapper">

        {/* this.state.notification &&
          <Notebanner
            notification={this.state.notification}
            unsetNotification={this.unsetNotification.bind(this)}
          />
         */}

        {/* this.state.error.name &&
          <Error
            error={this.state.error}
            unsetError={this.unsetError.bind(this)}
          />
         */}

        <Header />

        <Route
          exact={true}
          path='/'
          render={
            (props) => (
              <Home
                setError={this.setError.bind(this)}
                {...props}
              />)
          }
        />

        <Route
          exact={true}
          path='/projects'
          render={
            (props) => (
              <Projects
                setError={this.setError.bind(this)}
                {...props}
              />)
          }
        />

        <Route
          exact={true}
          path='/techstack'
          render={
            (props) => (
              <Techstack
                setError={this.setError.bind(this)}
                {...props}
              />)
          }
        />

        <Footer />

      </div>
    );
  }
}
