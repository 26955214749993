import React, { Component } from 'react';
import { Link } from 'react-router-dom';


export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Default states
        };
    }


    componentDidUpdate() {
    }


    render() {
        return (
            <div className="header">
                <Link to="/" className="header__logo">
                    Oskar de Smet
                </Link>
            </div>
        )
    }
}
