import React, { Component } from 'react';
import { Link } from 'react-router-dom';


export default class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <div className="footer-wrapper">
                    <div className="footer__navigation">
                        <Link to="/" className="footer__navigation__element">
                            About
                        </Link>
                        {/* <Link to="/blog" className="footer__navigation__element">
                            Blog
                        </Link> */}
                        {<Link to="/projects" className="footer__navigation__element">
                            Projects
                        </Link>}
                        <Link to="/techstack" className="footer__navigation__element">
                            Go-to Tech Stack
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}
