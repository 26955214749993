import React, { Component } from 'react';

export default class Project extends Component {
    render() {
        return (
            <div className="projects__element">
                <div className="projects__element__image">
                    <div className={"projects__element__image-inner projects__element__image-inner--" + this.props.image}></div>
                </div>
                <div className="projects__element__title">
                    {this.props.title}
                </div>
                <div className="projects__element__textbox">
                    {this.props.text}
                </div>
                <div className="projects__element__details">
                    {this.props.year &&
                        <div className="projects__element__details__element">
                            {this.props.year}
                        </div>
                    }
                    {this.props.type &&
                        <div className="projects__element__details__element">
                            {this.props.type}
                        </div>
                    }
                    {this.props.link_label &&
                        <div className="projects__element__details__element">
                            <a href={this.props.link_value}>{this.props.link_label}</a>
                        </div>
                    }
                    {this.props.link2_label &&
                        <div className="projects__element__details__element">
                            <a href={this.props.link2_value}>{this.props.link2_label}</a>
                        </div>
                    }
                </div>
            </div>
        )
    }
}
