import React, { Component } from 'react';
import Project from './project.js';

export default class Projects extends Component {
    render() {
        return (
            <div className="content-container content-container--no-margin">
                <div className="content-wrapper">
                    <div className="projects-container">

                        <Project
                            image="skinscan"
                            title="SkinScan"
                            text="A machine learning application (CNN) that evaluates risk of skin cancer based on images of a mole. Trained on over 65,000 medical images and highly accurate."
                            year="2021"
                            type="School CS project"
                            link_label="fitagen.com"
                            link_value="https://fitagen.com/"
                        />

                        <Project
                            image="gaming"
                            title="Online board games"
                            text="Developed series of online board games, including WebSocket-based framework that allows for rapid development of online board games."
                            year="2020"
                            type="Personal COVID project"
                        />

                        <Project
                            image="incuba"
                            title="InCuba.uhhu - first startup incubator in Cuba"
                            text="Built the first startup incubator at the University of Havana in Cuba and supported a batch of 10 startups."
                            year="2017"
                            type="Volunteering project"
                            link_label="incuba.uh.cu"
                            link_value="http://www.incuba.uh.cu/"
                            link2_label="LinkedIn"
                            link2_value="https://www.linkedin.com/company/incuba.uhhu/"
                        />

                        <Project
                            image="ceiling"
                            title="Kinetic Rain Ceiling"
                            text="Built an Arduino- and Raspberry-based kinetic rain ceiling with 900 rain drops and exhibited it to over 1 million visitors at Hessian Day 2014."
                            year="2014"
                            type="High school CS project"
                        />

                        <Project
                            image="remote"
                            title="System for remote control of power outlets"
                            text="Built an Arduino-based system for controlling power outlets from remote via a web interface."
                            year="2012"
                            type="High school CS project"
                        />

                        <Project
                            image="mod"
                            title="Mods for video games"
                            text="Developed mods for popular video games, including Edain Mod for ROTWK."
                            year="2008-2011"
                            type="Hobby"
                            link_label="modding-union.com"
                            link_value="https://modding-union.com/"
                        />

                        {/* <Project
                            image="image"
                            title="Title"
                            text="Text"
                            year="xxxxx"
                            type="xxxxx"
                            link_label="xxxxx"
                            link_value="xxxxx"
                            link2_label="xxxxx"
                            link2_value="xxxxx"
                        /> */}

                    </div>
                </div>
            </div>
        )
    }
}
