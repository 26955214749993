import React, { Component } from 'react'

export default class Techstack extends Component {
    render() {
        return (
            <div className="content-container content-container--no-margin">
                <div className="content-wrapper">
                    <div className="techstack-container">

                        <div className="techstack__element">
                            <div className="techstack__element__title">
                                Web development (client)
                            </div>
                            <div className="techstack__element__line">
                                React (JavaScript)
                            </div>
                        </div>

                        <div className="techstack__element">
                            <div className="techstack__element__title">
                                Web development (server)
                            </div>
                            <div className="techstack__element__line">
                                Express.js (JavaScript)
                            </div>
                            <div className="techstack__element__line">
                                Django (Python)
                            </div>
                            <div className="techstack__element__line">
                                Flask (Python)
                            </div>
                            <div className="techstack__element__line">
                                NGINX
                            </div>
                        </div>

                        <div className="techstack__element">
                            <div className="techstack__element__title">
                                Web development (database)
                            </div>
                            <div className="techstack__element__line">
                                MySQL
                            </div>
                            <div className="techstack__element__line">
                                MongoDB
                            </div>
                        </div>

                        <div className="techstack__element">
                            <div className="techstack__element__title">
                                Data Science
                            </div>
                            <div className="techstack__element__line">
                                Python
                            </div>
                            <div className="techstack__element__line">
                                R
                            </div>
                            <div className="techstack__element__line">
                                PyTorch (Python)
                            </div>
                            <div className="techstack__element__line">
                                TensorFlow (Python)
                            </div>
                        </div>

                        <div className="techstack__element">
                            <div className="techstack__element__title">
                                Video games
                            </div>
                            <div className="techstack__element__line">
                                Java
                            </div>
                            <div className="techstack__element__line">
                                C++
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
