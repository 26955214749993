import React, { Component } from 'react';
import { Link } from 'react-router-dom';


export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Default states
        };
    }


    componentDidMount() {
        try {

        } catch (error) {
            // Set error
            var name = "Website error";
            var message = "Website error";
            this.props.setError(name, message);
            // Track event
            /* this.trackEvent("Client error", "Landing_componentDidMount"); */
        }
    }


    componentDidUpdate() {
    }





    render() {
        return (
            <div className="content-container content-container--no-margin">
                <div className="content-wrapper">
                    <div className="home-container">
                        <div className="home__image"></div>

                        <div className="home__contactbox">
                            <a href="mailto:oskards95@gmail.com" className="home__contactbox__element">
                                odesmet@mit.edu
                            </a>
                            <a href="tel:+16176859382" className="home__contactbox__element">
                                +1-617-685-9382
                            </a>
                            <a href="https://www.linkedin.com/in/oskardesmet/" className="home__contactbox__element">
                                LinkedIn
                            </a>
                            <a href="https://www.instagram.com/oskards/" className="home__contactbox__element">
                                Instagram
                            </a>
                        </div>

                        <div className="home__summary">
                            I'm a graduate student at MIT, working on software products for health care. 
                            Since 2007, I have developed web apps, computer games (mods), and advanced analytics (machine learning) models. 
                            I worked in Management Consulting and Finance (Private Equity), focussing on digital technologies in health care. 
                            My education is in computer science and management, and I'm passionate about the American and European health care systems. 
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
